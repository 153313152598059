<template>
  <div class="user-info">
    <div class="shop-user-img">
      <img :src="baseInfo.avatar" alt="位元灵感"/>
    </div>
    <div class="user-nickName">{{ baseInfo.userName }}</div>
    <div class="company-name">{{ baseInfo.companyName }}</div>
    <!--    <div class="wy-level">-->
    <!--      <img :src="baseInfo.comboUrl" alt="位元灵感" class="level-img" v-if="baseInfo.comboUrl"/>-->
    <!--      <know-more content="仅展示当前版本，下个版本到期自动生效" v-if="baseInfo.comboUrl"/>-->
    <!--    </div>-->
    <div class="up-level-box">
      <div class="content" v-if="canUplevel">
        <div class="desc">设备使用数量：{{ baseInfo.seat }}</div>
        <button class="wy-button" @click="upLevel">去升级</button>
      </div>
    </div>
    <div class="btn-box">
      <button class="wy-button primary" @click="goIndex">{{ baseInfo.outTime ? '立即续费' : '购买套餐' }}</button>
    </div>
    <member-time-str :time="baseInfo.outTime" class="mt_16"></member-time-str>
  </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import MemberTimeStr from '@views/shop/components/shopUser/time.vue';
import {useStore} from "vuex";
import {goSomeWhere} from "@views/shop/utils";
import {useRoute} from "vue-router";
import useBaseInfo from "@utils/tool/shop/useBaseInfo";

const {baseInfo, canUplevel} = useBaseInfo()

const route = useRoute();
const store = useStore()
const {proxy} = getCurrentInstance();

const goIndex = () => {
  if (route.fullPath.indexOf('/shop/order') !== -1 && route.fullPath.indexOf('/shop/order/upLevel') === -1) {
    return
  }
  let path = baseInfo.value.outTime ? '/shop/order/renewal/2' : '/shop/order/buy/1'
  goSomeWhere(route, path)
}
const upLevel = () => {
  if (route.fullPath.indexOf('/shop/order/upLevel') !== -1) {
    return
  }
  goSomeWhere(route, '/shop/order/upLevel')
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopUserInfo",
});
</script>
<style lang="scss" scoped>
.user-info {
  width: 280px;
  height: 520px;
  box-sizing: border-box;
  text-align: center;
  background: url("~@/assets/img/person/base-bg.png") center center no-repeat;
  background-size: cover;
  border-radius: 16px;
  overflow: hidden;
  padding: 40px 16px 0;
  .shop-user-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .user-nickName {
    color: $shopFsColor;
    line-height: 1;
    margin-top: 24px;
    font-weight: bold;
    font-size: 16px;
  }

  .company-name {
    color: $shopFsColor;
    font-size: 14px;
    margin-top: 18px;
  }

  .wy-level {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    margin: 16px auto 0;

    .level-img {
      width: 80px;
      height: 24px;
      margin-right: 4px;
    }
  }

  .up-level-box {
    height: 32px;
    margin: 34px 0 92px;

    .desc {
      font-size: 12px;
    }

    .content {
      width: 232px;
      margin: auto;
      height: 100%;
      background: linear-gradient(90deg, #F7D9B4 0%, #FDE7CC 100%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4px 0 14px;
      font-size: 14px;
      box-sizing: border-box;
      color: #804907;

      .wy-button {
        background-color: #804907;
        color: #fff;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        border: none;
        padding: 0 12px;
      }
    }
  }

  .btn-box {
    padding-top: 40px;
    border-top: 1px dashed #fff;
    > button {
      width: 120px;
      height: 36px;
      line-height: 36px;
    }
  }
}
</style>